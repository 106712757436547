import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { withPrefix } from 'gatsby'
import OffersList from '../components/offersList'
import { Wrapper } from '../components/baseStyles'
import AnimationDropDownList from '../components/animationDropDownList'
import { FormattedMessage } from 'react-intl'
import SEO from '../components/seo'
import CustomButton from '../components/button'
import LocalisedLink from '../components/localisedLink'
import Slika from '../images/header/o_nama.svg'

const Title = styled.h2`
  font-family: 'Larsseit-Bold';
  font-size: 40px;
  line-height: 69px;
  text-align: center;
  color: #194092;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 27px;
    line-height: 35px;
  }
`

const Paragraph = styled.p`
  font-family: 'Grotesk-Medium';
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  color: #020512;

  width: calc((100% / 12) * 10);
  margin: 0 auto;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 25px;
  }
`

const offerHr = [
	{
		title: 'Pomoć pri kategorizaciji turističkog smještaja',
		paragraph:
			'Administrativni poslovi su često komplicirani i oduzimaju puno vremena. Litto te vodi korak po korak do ispunjavanja svih preduvjeta za sigurno i zakonito iznajmljivanje u turizmu.',
	},
	{
		title: 'Pouzdanog partnera u turizmu',
		paragraph:
			'S ciljem što uspješnijeg partnerstva prilagođavamo se tvojim potrebama i zahtjevima. Prvi korak je slušanje tvojih želja te dogovor oko načina poslovanja i pružanja usluga. Na raspolaganju smo od prve rezervacije pa sve do zaključenja turističke sezone. '
	},
	{
		title: 'Odnos zasnovan na povjerenju',
		paragraph:
			'Ugovor o suradnji se potpisuje na neodređeni period, ali ga možeš raskinuti u bilo kojem trenutku.',
	},
	{
		title: 'Moderne metode oglašavanja',
		paragraph:
			'Koliko ste puta čuli da je prvi dojam najvažniji? Prepusti Litto timu da tvoj oglas optimizira prema najboljim praksama iz svijeta oglašavanja u turizmu, a ti prati kako raste broj rezervacija!',
	},

	{
		title: 'Brigu o evidenciji i plaćanju poreza',
		paragraph:
			'Evidencija poreza na proviziju stranih portala u potpunosti je naša obaveza. Zaboravi na mjesečne odlaske u FINU i prepusti se Littu!',
	},
	{
		title: 'Vođenje računa i evidencije prometa',
		paragraph:
			'Od sklapanja suradnje vodimo sve administrativne poslove umjesto tebe, a na kraju godine dostavljamo ti evidenciju prometa i račune izdane prema gostima.',
	},
	{
		title: 'Usluge fotografiranja',
		paragraph:
			'Osim što govore tisuću riječi, fotografije tvog objekta ključne su za privlačenje pažnje potencijalnih gostiju. Istraživanja govore da 96% turista preskoči oglase bez slika i one s loše snimljenim fotografijama, zato prepusti našim stručnjacima da tvoj smještaj prikažu u najboljem izdanju.',
	},
	{
		title: 'Podršku za goste',
		paragraph:
			'Zadovoljstvo gostiju naš je glavni prioritet! Tvojim turistima nudimo proaktivnu podršku za operativna pitanja prije putovanja, ali i tijekom njihovog posjeta destinaciji.',
	},
	{
		title: 'Popuste za goste',
		paragraph:
			'Kako bismo tvojim gostima omogućili savršeno iskustvo ljetovanja na dalmatinskoj obali, u suradnji s našim partnerima smo osigurali niz uzbudljivih sadržaja po pristupačnim cijenama. Ponuda uključuje aktivnosti poput izletničkih tura, dnevnih izleta, pa sve do pouzdanih transfera i popusta u restoranima.',
	},
]

const offerEn = [
	{
		title: 'Onboarding assistance',
		paragraph:
			'Carrying out the administration process in Croatia may be a demotivating process. Our team can guide you through all steps which will ensure you meet all the requirements for safe and legal renting in tourism.',
	},
	{
		title: 'Reliable partner in tourism',
		paragraph:
			'We focus our business model on meeting your needs and requirements to make the partnership as successful as possible. Firstly, we listen to your needs and agree on doing business and providing services. We remain at your disposal from the first reservation until the end of the tourist season.',
	},
	{
		title: 'Partnership based on trust',
		paragraph:
			'The contract is signed for an indefinite period of time, and it can be terminated at any point.',
	},
	{
		title: 'Modern advertising methods',
		paragraph:
			'How many times have you heard that first impression matters most? Let the Litto team optimize your ad according to the best practices from the world of tourism advertising, and you follow the growing number of reservations!',
	},

	{
		title: 'Commisions and tax management',
		paragraph:
			'We take care of that part. Litto team will handle all your tax record on foreign agency commision.',
	},
	{
		title: 'Invoicing and yearly turnover',
		paragraph:
			'Depending on the type of your Litto partnership, we do manage your administrative tasks,  providing yearly report with traffic records and invoices issued to your guests.',
	},
	{
		title: 'Professional photoshoot',
		paragraph:
			'Research shows that 96% of tourists skip ads without pictures and those with poorly taken photos, so leave it to our experts to show your accommodation in the best edition.',
	},
	{
		title: 'Guest support',
		paragraph:
			'Guest satisfaction is our top priority! We offer proactive guest support while planning the trip and during their stay. ',
	},
	{
		title: 'Discounts for guests',
		paragraph:
			'We want your guests to have a perfect holiday experience on the Dalmatian coast, so in cooperation with our partners we provided a number of exciting facilities at affordable prices. The offer includes activities such as excursion tours, day trips, to reliable shuttle transfers and discounts in restaurants.',
	}
]
const Button = styled.div`
  text-align: center;
  margin-bottom: 45px;
`
const Services = ({ location, pageContext: { locale } }) => {
	const isHomePage = location.pathname === withPrefix('/')
	const isServices = true
	let offer = offerHr
	if (locale === 'en') offer = offerEn
	return (
		<Layout
			heroImage={Slika}
			isServices={isServices}
			isHomePage={isHomePage}
			title={
				locale === 'hr'
					? 'Odaberi model suradnje!'
					: 'Property Management Services'
			}
			locale={locale}
		>
			<SEO
				title={
					locale === 'hr'
						? 'Usluge | Odaberi model suradnje | Litto za Partnere'
						: 'Services | Choose your partnership model | Litto For Partners'
				}
				description={
					locale === 'hr'
						? 'Trebaš pomoć oko rezervacija ili nam možda želiš prepustiti kompletnu brigu oko svoje nekretnine? Odaberi model Litto suradnje koji ti najviše odgovara!'
						: 'Need some help with reservations or you’d like to break all ties with your property? Choose a collaboration model that suits you best!'
				}
				keywords={['Litto']}
				lang={locale}
			/>

			<Wrapper>
				<OffersList isServices={isServices} />
				<Button>
					<LocalisedLink to="/dogovori-sastanak" title={locale === 'hr' ? 'Dogovori sastanak| Litto Booking' : 'Arrange meeting | Litto Booking'}>
						<CustomButton bigger bold>
							<FormattedMessage id="meetingPage" />
						</CustomButton>
					</LocalisedLink>
				</Button>
				<Title>
					<FormattedMessage id="servicesTextTitle" />
				</Title>
				<Paragraph>
					<FormattedMessage id="servicesTextBody" />
				</Paragraph>

				<AnimationDropDownList options={offer} />
				<Paragraph>
					<FormattedMessage id="servicesEndText" />
				</Paragraph>
			</Wrapper>
		</Layout>
	)
}

export default Services
