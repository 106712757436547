import React, { Component } from 'react'
import styled from 'styled-components'
import H3 from './h3'
import { gray, black, yellow } from '../utlis/colors'
import Paragraph from './paragraph'

const Wrapper = styled.div`
  padding: 30px 0px;
  border-bottom: 1px ${gray} solid;
  transition: all ease 0.3s;
  cursor: pointer;
  &:last-child {
    border: none;
  }
`
const TitleWithCloseIcon = styled(H3)`
  font-family: 'Larsseit-Medium';
  font-size: 26px;
  position: relative;
  margin-bottom: 0;
  transition: all ease 0.2s;
  padding-right: 70px;
  &.active {
    margin-bottom: 15px;
    transition: all ease 0.2s;
  }
  &:before {
    content: '+';
    position: absolute;
    right: 29px;
    top: 5px;
    z-index: 2;
    color: ${black};
    transition: all ease 0.2s;
    @media screen and (max-width: 768px) {
      right: 15px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 0;
    transition: all ease 0.2s;
    font-weight: 100;
    width: 40px;
    height: 40px;
    background-color: ${yellow};
    line-height: 46px;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      right: 0px;
    }
  }
  &:hover:after {
    background: #e6d60f;
  }
  &:hover {
    color: #000;
  }
  &.active:after {
    color: ${gray};
    transform: rotate(90deg);
    transition: all ease 0.2s;
    background-color: #194092;
  }
  &.active:before {
    transform: rotate(45deg);
    transition: all ease 0.2s;
    color: white;
    right: 31px;
    @media screen and (max-width: 768px) {
      right: 17px;
    }
  }

  & + p {
    overflow: hidden;
    opacity: 0;
    height: 0;
    transition: all ease 0.1;
    white-space: pre-line;
  }
  &.active + p {
    opacity: 1;
    transition: all ease 0.2s;
    white-space: pre-line;
  }
  &:not(.active) + p {
    height: 0 !important;
    transition: all ease 0.2s;
  }
  @media screen and (max-width: 768px) {
    padding-right: 45px;
    font-size: 25px;
    line-height: 32px;
  }
`
const ListItem=styled.li`
  margin-left: 25px;
  margin-top: 10px;
`
const List = styled.ul`
  list-style-type: decimal;
`

class AnimationDropDown extends Component {
  state = {
    isActive: false,
  }
  toggleClassAndHeight = e => {
    this.setState({ isActive: !this.state.isActive })
    const paragraph = e.target.nextSibling
    paragraph.style.height = paragraph.scrollHeight + 'px'
  }
  render() {
    return (
      <Wrapper>
        <TitleWithCloseIcon
          color="blue"
          offset="0"
          className={this.state.isActive && 'active'}
          onClick={e => this.toggleClassAndHeight(e)}
          medium
        >
          {this.props.title}
        </TitleWithCloseIcon>
        <Paragraph>
          {this.props.list === undefined ? (
						<div dangerouslySetInnerHTML={{ __html: this.props.paragraph}} /> 
          ) : (
            
            <List>
            {this.props.listTitle}
            {this.props.list.map(record=>( 
              <ListItem>{record}</ListItem>
            ))}</List>
          )}
        </Paragraph>
      </Wrapper>
    )
  }
}

export default AnimationDropDown
