import React, { Component } from 'react'
import styled from 'styled-components'
import AnimationDropDown from './animationDropDown'
import { Section } from './baseStyles'

const ExtendedSection = styled(Section)`
  width: calc((100% / 12) * 8);
  @media screen and (max-width:768px) {
    width:100%;
    padding:0;
  }
  `
class AnimationDropDownList extends Component {
  render() {
    const { options } = this.props
    return (
      <ExtendedSection>
        <div style={{ marginBottom: '50px' }}>
          {options.map((item, index) => {
            return <AnimationDropDown
              key={index}
              index={index}
              title={item.title}
              paragraph={item.paragraph}
              list={item.list}
              listTitle={item.listTitle}
            />
          })}
        </div>
      </ExtendedSection>

    )
  }
}
export default AnimationDropDownList